<template>
    <div class="CkManage">
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
            </div>

            <div slot="header" class="clearfix">
                <div>
                    仪器仪表入库
                </div>
                <el-divider></el-divider>

                <el-form :inline="true" @submit.native.prevent>
                    <el-form-item label="仪器条码">
                        <el-input ref="deviceCodeRef" v-model="deviceCode" @keydown.enter.native="courierNumEnterDown"
                            @keyup.enter.native="courierNumEnterUp" placeholder="请输入标签查询" autocomplete="off"
                            class="deviceCode"></el-input>
                        <span class="hint">( 可扫码输入 )</span>
                    </el-form-item>
                </el-form>
            </div>

            <!-- 列表 -->
            <el-table :data="deviceCrkList" border style="width: 100%; font-size: 13px;" v-loading="loading"
                element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="oddNum" label="批次号" align="center"> </el-table-column>
                <el-table-column prop="deviceOneClass" label="仪器分类" align="center"> </el-table-column>
                <el-table-column prop="deviceName" label="仪器名称" align="center">
                </el-table-column>
                <el-table-column prop="deviceRealName" label="实际名称" align="center">
                </el-table-column>
                <el-table-column prop="deviceType" label="仪器型号" align="center">
                </el-table-column>
                <el-table-column prop="deviceNum" label="仪器编号" align="center">
                </el-table-column>
                <el-table-column prop="crkTypeName" label="出库类型" align="center">
                </el-table-column>
                <el-table-column prop="ckJbr" label="出库经办人" align="center">
                </el-table-column>
                <el-table-column prop="ckDate" label="出库日期" align="center">
                </el-table-column>
                <el-table-column prop="ckTime" label="出库时间" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="删除" class="delColor"
                            @click="handleRemove(scope.row.deviceCode)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="ckInfo">
                <el-form :model="deviceRkInfo" :rules="rules" ref="deviceRkInfo">
                    <div v-if="crkType === '00'">
                        <el-form-item label="入库经办人" :label-width="formLabelWidth" prop="rkJbr">
                            <el-input v-model="deviceRkInfo.rkJbr" autocomplete="off" class="input" placeholder="请输入入库经办人"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="crkType === '01'">
                        <el-form-item label="外观是否正常" :label-width="formLabelWidth" prop="isWgjcOk">
                            <el-radio v-model="deviceRkInfo.isWgjcOk" :label=0>是</el-radio>
                            <el-radio v-model="deviceRkInfo.isWgjcOk" :label=1>否</el-radio>
                        </el-form-item>

                        <el-form-item label="外观检查图片" :label-width="formLabelWidth" prop="wgjcImage">
                            <div class="bannerbox">
                                <div class="banners" v-for="(item, index) in imgsList" :key="index">
                                    <i class="el-icon-error cha" @click="handleDel(item, index)" style="font-size:30px"></i>
                                    <uploadImg :imageUrl="item.imageName" @imgVal="imgVal($event, item)" :type="item.type">
                                    </uploadImg>
                                </div>
                                <div class="upload1" v-if="imageView">
                                    <el-upload class="upload-demo1" action :show-file-list="false" multiple drag
                                        :http-request="handleUploadFile">
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">点击上传
                                        </div>
                                    </el-upload>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="性能检测" :label-width="formLabelWidth" prop="xnCheck">
                            <!-- <el-radio v-model="deviceRkInfo.isWgjcOk" :label='00'>修后性能正常</el-radio>
                            <el-radio v-model="deviceRkInfo.isWgjcOk" :label=1>修后性能待查</el-radio> -->
                            <el-radio v-model="deviceRkInfo.xnCheck" v-for="(item, index) in xnMaintainList" :key="index"
                                :label="item.code">{{
                                    item.name }}</el-radio>
                        </el-form-item>
                        <el-form-item label="入库经办人" :label-width="formLabelWidth" prop="rkJbr">
                            <el-input v-model="deviceRkInfo.rkJbr" autocomplete="off" class="input" placeholder="请输入入库经办人"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="crkType === '02'">
                        <el-form-item label="是否合格" :label-width="formLabelWidth" prop="isCheckoutOk">
                            <el-radio v-model="isCheckoutOk" :label=0>是</el-radio>
                            <el-radio v-model="isCheckoutOk" :label=1>否</el-radio>
                        </el-form-item>
                        <el-form-item :label="tagLabel" :label-width="formLabelWidth" required prop="xyTag">
                            <uploadImg2 :imageUrl="deviceRkInfo.xyTag" @imgVal2="updateImg($event, 'xyTag')" />
                        </el-form-item>
                        <!-- <el-form-item :label="cerLabel" :label-width="formLabelWidth" required prop="xyCer">
                            <uploadImg2 :imageUrl="deviceRkInfo.xyCer" @imgVal2="updateImg($event, 'xyCer')" />
                        </el-form-item> -->
                        <el-form-item :label="cerLabel" :label-width="formLabelWidth" prop="xyCer" class="input-upload">
                            <el-input v-model="deviceRkInfo.xyCer" autocomplete="off" placeholder="请选择pdf文件上传"></el-input>
                            <input type="file" ref="import" style="display: none" @change="uploadIn($event)" />
                            <el-button type="info" plain @click="handleIn" style="margin-left: 10px;">选择文件</el-button>
                        </el-form-item>
                        <el-form-item label="外观是否正常" :label-width="formLabelWidth" prop="isWgjcOk">
                            <el-radio v-model="deviceRkInfo.isWgjcOk" :label=0>是</el-radio>
                            <el-radio v-model="deviceRkInfo.isWgjcOk" :label=1>否</el-radio>
                        </el-form-item>

                        <el-form-item label="外观检查图片" :label-width="formLabelWidth" prop="wgjcImage">
                            <div class="bannerbox">
                                <div class="banners" v-for="(item, index) in imgsList" :key="index">
                                    <i class="el-icon-error cha" @click="handleDel(item, index)" style="font-size:30px"></i>
                                    <uploadImg :imageUrl="item.imageName" @imgVal="imgVal($event, item)" :type="item.type">
                                    </uploadImg>
                                </div>
                                <div class="upload1" v-if="imageView">
                                    <el-upload class="upload-demo1" action :show-file-list="false" multiple drag
                                        :http-request="handleUploadFile">
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">点击上传
                                        </div>
                                    </el-upload>
                                </div>

                            </div>
                        </el-form-item>
                        <el-form-item label="性能检测" :label-width="formLabelWidth" prop="xnCheck">
                            <el-radio v-model="deviceRkInfo.xnCheck" v-for="(item, index) in xnCheckList" :key="index"
                                :label="item.code">{{
                                    item.name }}</el-radio>
                        </el-form-item>
                        <el-form-item label="入库经办人" :label-width="formLabelWidth" prop="rkJbr">
                            <el-input v-model="deviceRkInfo.rkJbr" autocomplete="off" class="input" placeholder="请输入入库经办人"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="crkType === '03'">
                        <el-form-item label="外观是否正常" :label-width="formLabelWidth" prop="isWgjcOk">
                            <el-radio v-model="deviceRkInfo.isWgjcOk" :label=0>是</el-radio>
                            <el-radio v-model="deviceRkInfo.isWgjcOk" :label=1>否</el-radio>
                        </el-form-item>

                        <el-form-item label="外观检查图片" :label-width="formLabelWidth" prop="wgjcImage">
                            <div class="bannerbox">
                                <div class="banners" v-for="(item, index) in imgsList" :key="index">
                                    <i class="el-icon-error cha" @click="handleDel(item, index)" style="font-size:30px"></i>
                                    <uploadImg :imageUrl="item.imageName" @imgVal="imgVal($event, item)" :type="item.type">
                                    </uploadImg>
                                </div>
                                <div class="upload1" v-if="imageView">
                                    <el-upload class="upload-demo1" action :show-file-list="false" multiple drag
                                        :http-request="handleUploadFile">
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">点击上传
                                        </div>
                                    </el-upload>
                                </div>

                            </div>
                        </el-form-item>
                        <el-form-item label="性能检测" :label-width="formLabelWidth" prop="xnCheck">
                            <el-radio v-model="deviceRkInfo.xnCheck" v-for="(item, index) in xnBackList" :key="index"
                                :label="item.code">{{
                                    item.name }}</el-radio>
                        </el-form-item>
                        <el-form-item label="入库经办人" :label-width="formLabelWidth" prop="rkJbr">
                            <el-input v-model="deviceRkInfo.rkJbr" autocomplete="off" class="input" placeholder="请输入入库经办人"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="crkType === '04'">
                        <el-form-item label="入库经办人" :label-width="formLabelWidth" prop="rkJbr">
                            <el-input v-model="deviceRkInfo.rkJbr" autocomplete="off" class="input" placeholder="请输入入库经办人"></el-input>
                        </el-form-item>
                    </div>

                </el-form>
                <div slot="footer" class="dialog-footer" v-show="btnShow">
                    <el-button class="btn" @click="sure('deviceRkInfo')">确 定</el-button>
                    <el-button @click="closeInfo('deviceRkInfo')">取 消</el-button>
                </div>
            </div>


        </el-card>

    </div>
</template>

<script>
import uploadImg from '@/components/UploadImg/index'
import uploadImg2 from '@/views/device/components/index'
import { uploadFile } from '@/api/file'
import { crkTypeList, getCkInfo, deviceRKRules, rkInventory } from '@/api/deviceCrk.js'
// import { deviceStatusList, crkStatusList } from '@/api/device.js'
// import { getHouseAll } from '@/api/house.js'
export default {
    components: {
        uploadImg,
        uploadImg2
    },
    data() {
        return {
            crkType: null,
            deviceCode: null,
            oddNum: null,
            isCheckoutOk: 0,
            deviceCrkList: [],
            crkTypeList: crkTypeList,
            xnMaintainList: [{ code: '00', name: '修后性能正常' }, { code: '06', name: '修后性能待查' }, { code: '12', name: '修后性能异常' }],
            xnCheckList: [{ code: '00', name: '校后性能正常' }, { code: '07', name: '校后性能待查' }, { code: '13', name: '校后性能异常' }],
            xnBackList: [{ code: '00', name: '还后性能正常' }, { code: '08', name: '还后性能待查' }, { code: '14', name: '还后性能异常' }],
            // houseList: [],
            deviceRkInfo: {
                deviceCrkList: [],
                crkType: this.crkType,
                isWgjcOk: 0,
                wgjcImage: null,
                xnCheck: '00',
                isCheckoutOk: null,
                checkDate: null,
                xyTag: null,
                xyCer: null,
                xyCerPath: null,
                rkJbr: null,
                rkDate: null,
                rkTime: null,

            },
            rules: deviceRKRules,
            loading: false,
            dialogFormVisible: false,
            formLabelWidth: '120px',
            keyDownTime: 0,
            keyUpTime: 0,
            imgsList: [],
            imageView: true,
            tagLabel: '合格标签',
            cerLabel: '合格证书',
            btnShow: false  //隐藏提交按钮
        }
    },
    mounted() {
        this.onAuxCodeRef()
        // this.loadHouses()
    },
    methods: {
        onAuxCodeRef() {
            this.$nextTick(() => {
                this.$refs.deviceCodeRef.focus()
            })
        },
        // async loadHouses() {
        //     await getHouseAll().then(res => {
        //         if (res.code === '000000') {
        //             this.houseList = res.t
        //         }
        //     })
        // },

        loadAuxilaryInfo() {
            if (this.deviceCode) {
                if (this.crkType) {
                    if (this.crkType !== '00') {
                        if (this.deviceCrkList.length > 0) {
                            this.$message.warning('该出库类型下，一次只能入库一件仪器！')
                        } else {
                            this.getCkInfo()

                        }
                    } else {
                        this.getCkInfo()
                    }
                } else {
                    this.getCkInfo()
                }
            }

        },

        getCkInfo() {
            this.$fullScreenLoading.show("查询中...");
            getCkInfo(this.oddNum, this.deviceCode).then(res => {
                setTimeout(() => {
                    // this.loading = false
                    if (res.code === '000000') {

                        const index = this.crkTypeList.findIndex(item => item.code === res.t.crkType)
                        res.t.crkTypeName = this.crkTypeList[index].name

                        if (this.deviceCrkList.length > 0) {
                            if (this.deviceCrkList.findIndex(item => item.deviceCode === res.t.deviceCode) === -1) {
                                this.deviceCrkList.push(res.t)
                            } else {
                                this.$message.warning('该辅助工具已添加到入库清单中，请勿重复添加！')
                            }
                            console.log(this.deviceCrkList)
                        } else {
                            this.deviceCrkList.push(res.t)
                            this.oddNum = res.t.oddNum
                            console.log(this.deviceCrkList)

                        }

                        var count = 1;
                        this.deviceCrkList.forEach(item => {
                            item.seq = count++
                        })
                        const crk = this.deviceCrkList[0].crkType
                        this.crkType = crk
                    }
                    this.btnShow = true
                    this.deviceCode = null
                    this.$fullScreenLoading.hide();
                }, 200)
                // this.$fullScreenLoading.hide()

            })
        },

        //提交出库单
        sure(deviceRkInfo) {
            this.$fullScreenLoading.show("入库中...");
            if (this.imgsList) {
                this.deviceRkInfo.wgjcImage = '';
                this.imgsList.forEach(item => {
                    this.deviceRkInfo.wgjcImage += (item.imageName + ";")
                })
            }
            this.deviceRkInfo.isCheckoutOk = this.isCheckoutOk
            this.deviceRkInfo.crkType = this.crkType

            this.$refs[deviceRkInfo].validate(valid => {
                if (valid) {
                    this.deviceRkInfo.deviceCrkList = this.deviceCrkList
                    this.deviceRkInfo.crkType = this.crkType
                    rkInventory(this.deviceRkInfo).then(res => {

                        setTimeout(() => {
                            if (res.code === '000000') {
                                this.$message.success('入库成功')
                            }
                            this.imgsList = []
                            this.$refs[deviceRkInfo].resetFields()
                            this.deviceCrkList = []
                            this.$fullScreenLoading.hide();
                            this.$router.go(-1)
                        }, 500)
                    })

                }
            })
        },

        //删除
        handleRemove(deviceCode) {
            this.$confirm('是否移除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let auxiIndex = this.deviceCrkList.findIndex(item => item.deviceCode === deviceCode)
                if (auxiIndex !== -1) {
                    this.deviceCrkList.splice(auxiIndex, 1)
                    this.oddNum = null
                    this.$message.success('移除成功！')
                } else {
                    this.$message.error('移除失败！')
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消报废'
                })
            })
        },

        courierNumEnterDown() {
            this.keyDownTime = new Date().getTime()
        },
        courierNumEnterUp() {
            this.keyUpTime = new Date().getTime()
            if (this.keyUpTime - this.keyDownTime < 30) {
                console.log("扫码完成")
                this.loadAuxilaryInfo()
            } else {
                this.loadAuxilaryInfo()
            }
        },

        closeInfo() {
            this.$refs['deviceRkInfo'].resetFields();
            this.deviceCrkList = []
            this.imgsList = []
            this.deviceCode = null
        },

        handleUploadFile(file) {
            const formData = new FormData()
            formData.append('file', file.file)

            uploadFile(formData).then(res => {
                if (res.code === '000000') {
                    this.imageUrl = res.data.importFileName
                    const obj = {
                        imageName: res.data.importFileName
                    }
                    this.imgsList.push(obj)
                    // this.deviceRkInfo.wgjcImage = '';
                    // this.imgsList.forEach(item => {
                    //     this.deviceRkInfo.wgjcImage += (item.imageName + ";")
                    // })
                    if (this.imgsList.length > 3) {
                        this.imageView = false;
                    }

                }
            })
        },
        handleDel(item, index) {
            this.imgsList.splice(index, 1)
            console.log("handleDel")
            console.log(this.imgsList)
            console.log(this.deviceRkInfo.wgjcImage)
            this.imageView = true
        },
        // 图片上传后地址
        imgVal(val, item) {
            item.imageName = val
        },

        // 上传的图片
        updateImg(val, item) {
            this.deviceRkInfo[item] = val

        },

        //上传文件
        //点击导入
        handleIn() {
            this.$refs.import.click();
        },
        //上传文件
        uploadIn(event) {
            console.log('上传文件')
            let file = event.target.files; //获得文件
            for (let i = 0; i < file.length; i++) {
                //    上传类型判断
                let fileName = file[i].name;
                let idx = fileName.lastIndexOf(".");
                if (idx != -1) {
                    let ext = fileName.substr(idx + 1).toUpperCase();
                    ext = ext.toLowerCase();
                    if (ext !== "pdf") {
                        this.$message.warning('上传文件只能是pdf文件!')
                        return;
                    } else {
                        this.importFile = file[i];
                    }
                }
            }
            let formData = new FormData(); //数据
            formData.append("file", this.importFile);
            uploadFile(formData)
                .then(res => {
                    if (res.code === '000000') {
                        this.deviceRkInfo.xyCerPath = res.data.importFileName
                        this.deviceRkInfo.xyCer = res.data.fileName
                        console.log(this.deviceRkInfo.xyCer)
                        console.log(this.deviceRkInfo.xyCerPath)
                    }
                })
                .catch((e) => {
                    console.log(e)
                });
        },

    },

    watch: {
        isCheckoutOk: function () {
            this.isCheckoutOk === 0 ? this.tagLabel = '合格标签' : this.tagLabel = '不合格标签'
            this.isCheckoutOk === 0 ? this.cerLabel = '合格证书' : this.cerLabel = '不合格证书'
        },
        deviceCrkList: function () {
            if (this.deviceCrkList.length === 0) {
                this.btnShow = false
                this.crkType = null
            }
        }
    }

}


</script>

<style lang="scss" scoped>
.CkManage {

    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }

        .crkType {
            height: 50px;
            width: 100%;
            text-align: left;
        }
    }


    .el-pagination,
    .el-form-item,
    .clearfix {
        text-align: left;

        .deviceCode {
            width: 600px;
            margin-right: 20px;
        }

        .hint {
            color: red;
        }
    }

    .ckSubmit {
        text-align: right;
    }

    .delColor {
        color: red;
    }

    .ckInfo {
        margin: 30px 0;

        .input {
            width: 60%;
        }

        .dialog-footer {
            text-align: left;
            margin-left: 120px;
        }
    }

    .upload1 {
        width: 360px;
        height: 240px;
        margin-right: 20px;
        margin-bottom: 20px;

        .el-upload-dragger,
        .el-upload--text,
        .upload-demo1 {
            width: 100%;
            height: 100%;
        }

        .el-upload-dragger .el-icon-upload {
            margin-top: 60px;
        }

        .el-upload__text {
            margin-top: -10px;
        }
    }

    .bannerbox {
        display: flex;
        flex-wrap: wrap;
    }

    .banners {
        width: 360px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;

        .cha {
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            z-index: 2;
        }

        .el-upload__tip {
            margin-top: 7px;
        }

        .el-upload {
            width: 100%;
        }

        .el-upload-dragger {
            border: none;
            border-radius: 0;
        }
    }

    .avatar {
        width: 100%;
        height: 100%;
    }

    .input-upload {
        width: 500px;
        position: relative;

        .el-button {
            position: absolute;
            top: 0px;
            right: -100px;
        }
    }


}
</style>